import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { store } from "./app/store";
import Register from "./components/register";
import Bingo from "./components/bingo";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Wrapper from "./components/wrapper";
import FindBooks from "./components/find_books/find_books";

const container = document.getElementById("root")!;
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Register />,
  },
  {
    path: "/:userId",
    element: <Bingo />,
  },
  {
    path: "/books",
    element: <FindBooks />,
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Wrapper>
        <RouterProvider router={router} />
      </Wrapper>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
