import { useState } from "react";
import { Button, Col, Modal, Table } from "react-bootstrap";
import { Check } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { Book, Category } from "../../app/types";
import { getYear } from "../../functions/date";
import {
  useClearReadMutation,
  useUpdateBookMutation,
} from "../../services/api";

interface SetBookModalProps {
  show: boolean;
  close: () => void;
  books: Book[];
  currentBook?: Book;
  category?: Category;
  board_id: number;
}

export default function SetBookModal(props: SetBookModalProps) {
  const { show, category, close, books, currentBook, board_id } = props;

  const [selectedBook, setSelectedBook] = useState<Book | undefined>(
    currentBook
  );

  const { userId: user_id = "" } = useParams();

  const [clearRead] = useClearReadMutation();
  const [updateBook] = useUpdateBookMutation();

  if (!category) {
    return <div />;
  }

  return (
    <Modal show={show} size="lg" className="set-book-modal">
      <Modal.Header>
        <Modal.Title>Set Book for {category.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Title</th>
              <th>Author</th>
              <th>Hard Mode</th>
              <th>Read</th>
            </tr>
          </thead>
          <tbody>
            {books.map((book) => (
              <tr
                onClick={() => setSelectedBook(book)}
                className={`${
                  selectedBook?.id === book.id ||
                  (typeof selectedBook == "undefined" &&
                    currentBook?.id === book.id)
                    ? "active"
                    : ""
                }`}
                key={book.id}
              >
                <td>{book.title}</td>
                <td>{book.author}</td>
                <td>
                  {book.category_array.find(
                    (bc) => bc.category_id === category.id
                  )?.hard_mode && <Check size={24} />}
                </td>
                <td>{book.read && <Check size={24} />}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Col>
          <Button
            variant="light"
            onClick={async () => {
              await clearRead({
                user_id,
                year: getYear(),
                position: category.position,
                board_id,
              });
              setSelectedBook(undefined);
              close();
            }}
          >
            Clear Category
          </Button>
        </Col>
        <Col
          style={{
            textAlign: "right",
            gap: 10,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="light"
            onClick={() => {
              setSelectedBook(undefined);
              close();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={typeof selectedBook === "undefined"}
            onClick={async () => {
              if (currentBook) {
                await updateBook({
                  ...currentBook,
                  position: undefined,
                  board_id: undefined,
                });
              }

              await updateBook({
                ...selectedBook,
                position: category.position,
                board_id,
              });
              setSelectedBook(undefined);
              close();
            }}
          >
            Save
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
}
