import { Button, Container } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";

import { useCreateUserMutation } from "../services/api";
import { useAppDispatch } from "../app/hooks";
import { addToast } from "../app/reducers/toast";

function Register() {
  const navigate = useNavigate();
  const [createUser] = useCreateUserMutation();
  const dispatch = useAppDispatch();

  const handleCreateUser = async () => {
    try {
      const user = await createUser(null).unwrap();
      localStorage.setItem("user", user.id);
      navigate(`/${user.id}`);
    } catch {
      dispatch(
        addToast({
          header: "Error Registering",
          body: "Unable to register. Please try again.",
          variant: "danger",
        })
      );
    }
  };

  const user_id = localStorage.getItem("user");

  if (user_id) {
    return <Navigate to={`/${user_id}`} />;
  }

  return (
    <Container>
      <p>
        Since this bingo tracker does not require any personal information, you
        will be assigned a random account ID when registering.
        <br />
        To ensure you retain access to your bingo card and tracked books, please
        bookmark the page you're redirected to after registering.
        <br />
        <br />
        <span className="text-danger bold">
          There is no way to recover an account if you lose your ID.
        </span>
      </p>
      <Button onClick={handleCreateUser} variant="primary">
        Register
      </Button>
    </Container>
  );
}

export default Register;
