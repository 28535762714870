import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addToast } from "../../app/reducers/toast";
import { getYear } from "../../functions/date";
import { useCreateBoardMutation } from "../../services/api";

interface BoardModalProps {
  show: boolean;
  setShowModal: (value: boolean) => void;
}

type BoardForm = {
  name: string;
};

export default function BoardModal(props: BoardModalProps) {
  const { show, setShowModal } = props;
  const { handleSubmit, control } = useForm<BoardForm>();

  const theme = useAppSelector((state) => state.settings.theme);

  const [createBoard] = useCreateBoardMutation();

  const { userId: user_id = "" } = useParams();
  const year = getYear();

  const dispatch = useAppDispatch();

  const onSubmit = async (data: BoardForm) => {
    const { name } = data;
    await createBoard({ name, year, user_id });
    dispatch(
      addToast({
        header: "Success",
        body: "Successfully created board",
        variant: "success",
      })
    );
    setShowModal(false);
  };

  return (
    <Modal show={show} contentClassName={`shadow ${theme}`}>
      <Modal.Header>
        <Modal.Title>Create Board</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)} className="board-form">
          <Row>
            <Col>
              <Form.Group controlId="formName">
                <Form.Label>Board Name</Form.Label>
                <Controller
                  control={control}
                  name="name"
                  defaultValue=""
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      placeholder="Board Name"
                      required
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="buttons">
            <Button
              variant="secondary"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
