import { Badge, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Board, Book, Category } from "../../app/types";
import Rating from "../shared/rating";

interface BoardCellProps {
  books: Book[];
  category: Category;
  board: Board;
  setBooks: (books: Book[]) => void;
  openModal: () => void;
  setCategory: (category: Category) => void;
  setCurrentBook: (book?: Book) => void;
}

export default function BoardCell(props: BoardCellProps) {
  const {
    books,
    category,
    board,
    setBooks,
    setCategory,
    openModal,
    setCurrentBook,
  } = props;

  const image = books.find(
    (book) => book.position === category.position && book.board_id === board.id
  )?.image;

  const defaultBook = books.find(
    (book) => book.position === category.position && book.board_id === board.id
  );

  const settableBooks = books
    .filter(
      (book) =>
        (book.position === category.position && book.board_id === board.id) || // currently selected book
        (book.position === null &&
          book.category_array.some((bc) => bc.category_id === category.id)) // book can fit this category and isn't already set
    )
    .filter(
      (book) =>
        !books
          .filter((b) => b.position && b.board_id === board.id)
          .map((b) => b.author)
          .includes(book.author)
    );

  if (defaultBook) settableBooks.push(defaultBook);

  const handleClick = () => {
    setBooks(settableBooks);
    setCategory(category);
    setCurrentBook(defaultBook);
    openModal();
  };

  return (
    <Container
      fluid
      className={`border h-100 prevent-select ${defaultBook?.read && "read"}`}
    >
      <div className="cell-content" onClick={handleClick}>
        {defaultBook?.category_array.find(
          (bc) => bc.category_id === category.id
        )?.hard_mode && (
          <Badge bg="secondary" className="badge">
            H
          </Badge>
        )}
        {image && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{category.name}</Tooltip>}
            rootClose
          >
            <div className="image-container">
              <img src={image} alt={`${defaultBook?.title} cover`} />
              <div className="rating">
                <Rating rating={defaultBook?.rating} direction="vertical" />
              </div>
            </div>
          </OverlayTrigger>
        )}
        {defaultBook && !image && (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{category.name}</Tooltip>}
            rootClose
          >
            <div className="category-content no-image">
              <span>
                <h4>{defaultBook?.title}</h4>
                <span>{defaultBook?.author}</span>
              </span>
            </div>
          </OverlayTrigger>
        )}
        {!defaultBook && (
          <div className="category-content">{category.name}</div>
        )}
      </div>
    </Container>
  );
}
